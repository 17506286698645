const SliderHeader = () => {
    return (
        <div className='slider-header'>
            <div className='slider-textzone'>
                <div className='title'> A NE PAS RATER </div>
                <div className='txt-20'> PRÊT ? </div>
            </div>
        </div>
    )
}

export default SliderHeader;

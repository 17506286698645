const LoginHeader = () => {
    return (
        <div className='login-header'>
            <div className='login-textzone'>
                <div className='title'> CONNECTEZ VOUS </div>
                <div className='txt-20 fw-light'> VIVEZ VOTRE PASSION </div>
            </div>
        </div>
    )
}

export default LoginHeader;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/hockey.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sports-header {
    width: 100%;
    height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    display: flex;
    align-items: center;
}

.sports-textzone {
    color: #fff;
    width: 100%;
    max-width: 1700px;
    padding: 0 130px;
    margin-left: auto;
    margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/style/sports.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,yDAA2C;IAC3C,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".sports-header {\n    width: 100%;\n    height: 420px;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-image: url('../img/hockey.jpeg');\n    background-position: center;\n    display: flex;\n    align-items: center;\n}\n\n.sports-textzone {\n    color: #fff;\n    width: 100%;\n    max-width: 1700px;\n    padding: 0 130px;\n    margin-left: auto;\n    margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/bars_supporters.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bars-header {
    width: 100%;
    height: 420px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: center;
    display: flex;
    align-items: center;
}

.bars-textzone {
    color: #fff;
    width: 100%;
    max-width: 1700px;
    padding: 0 130px;
    margin-left: auto;
    margin-right: auto;
}

.bar-description {
    border-radius: 30px;
    background-color: #fff;
    width: 80%;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    overflow: hidden;
}

.bar-description-info {
    width: 100%;
    min-height: 400px;
}

.bar-description-info-text {
    flex: 2 1;
    padding-left: 60px;
    padding-right: 20px;
    padding-top: 34px;
    padding-bottom: 34px;
}

.bar-description-info-picture {
    flex: 1 1;
    max-width: 300px;
}

.bar-description-info-picture img {
    height: 100%;
    max-width: 300px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    object-fit: cover;
}
`, "",{"version":3,"sources":["webpack://./src/style/bars.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,yDAAmD;IACnD,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;IACtB,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,SAAO;IACP,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,SAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,6BAA6B;IAC7B,gCAAgC;IAChC,iBAAiB;AACrB","sourcesContent":[".bars-header {\n    width: 100%;\n    height: 420px;\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-image: url('../img/bars_supporters.png');\n    background-position: center;\n    display: flex;\n    align-items: center;\n}\n\n.bars-textzone {\n    color: #fff;\n    width: 100%;\n    max-width: 1700px;\n    padding: 0 130px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.bar-description {\n    border-radius: 30px;\n    background-color: #fff;\n    width: 80%;\n    max-width: 1700px;\n    margin-left: auto;\n    margin-right: auto;\n    color: #000;\n    overflow: hidden;\n}\n\n.bar-description-info {\n    width: 100%;\n    min-height: 400px;\n}\n\n.bar-description-info-text {\n    flex: 2;\n    padding-left: 60px;\n    padding-right: 20px;\n    padding-top: 34px;\n    padding-bottom: 34px;\n}\n\n.bar-description-info-picture {\n    flex: 1;\n    max-width: 300px;\n}\n\n.bar-description-info-picture img {\n    height: 100%;\n    max-width: 300px;\n    border-top-right-radius: 30px;\n    border-bottom-right-radius: 30px;\n    object-fit: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const BarsHeader = () => {
    return (
        <div className='bars-header'>
            <div className='bars-textzone mt-5'>
                <div className='title center'>NOS BARS PARTENAIRES</div>
            </div>
        </div>
    )
}

export default BarsHeader;

import '../../style/cards.css';

const CardBars = ({ bars }) => {

    return (
        <div className="top-events mb-5">
            <div className="mt-5 mb-5">
                <div className="fw-bold txt-40 center">BARS PARTENAIRES</div>
                <div className="fw-light txt-30 center">A VISITER</div>
            </div>
            <div className="inline-flex bar-list">
                {bars.length > 0 ?
                bars.map((bar) => (
                    <div className="card horizontal-scroll-card">
                        <a href={'/bar/' + bar.id}>
                            <img src={bar.image ? "/image/" + bar.image : "/image/no-image.png"} className="card-img-top" alt="..." />
                            <div className="card-body">
                                <div className="card-text fw-medium">{bar.name}</div>
                                <div className="card-text opacity-75">{bar.address}, {bar.postcode} {bar.city}</div>
                            </div>
                        </a>
                    </div>
                ))
                :(  <div className="card horizontal-scroll-card">
                        <img src="/image/no-image.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div className="card-text fw-medium">Pas de favoris</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default CardBars;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    padding: 60px 80px;
    position: absolute;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.nav-link {
    color: #fff;
    font-weight: 300;
    font-size: 20px;
    margin: 0px 12px;
}

.nav-link:hover {
    color: #fff;
    transform: scale(1.1, 1.1);
}

.nav-button {
    border: 2px solid #fff;
}
`, "",{"version":3,"sources":["webpack://./src/style/navbar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".navbar {\n    padding: 60px 80px;\n    position: absolute;\n    max-width: 1700px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.nav-link {\n    color: #fff;\n    font-weight: 300;\n    font-size: 20px;\n    margin: 0px 12px;\n}\n\n.nav-link:hover {\n    color: #fff;\n    transform: scale(1.1, 1.1);\n}\n\n.nav-button {\n    border: 2px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../img/handball_shoot.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    width: 100%;
    background-color: #000;
    color: #fff;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position: bottom left;
}

.footer a {
    color: #fff;
    text-decoration: none;
}

footer {
    width: 80%;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 0px 0px 0px;
}
`, "",{"version":3,"sources":["webpack://./src/style/footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,WAAW;IACX,yDAAkD;IAClD,4BAA4B;IAC5B,gCAAgC;AACpC;;AAEA;IACI,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,yBAAyB;AAC7B","sourcesContent":[".footer {\n    width: 100%;\n    background-color: #000;\n    color: #fff;\n    background-image: url('../img/handball_shoot.png');\n    background-repeat: no-repeat;\n    background-position: bottom left;\n}\n\n.footer a {\n    color: #fff;\n    text-decoration: none;\n}\n\nfooter {\n    width: 80%;\n    max-width: 1700px;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 40px 0px 0px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

const ProfilHeader = () => {
    return (
        <div className='profil-header'>
            <div className='profil-textzone mt-5'>
                <div className='title center'>MON PROFIL</div>
            </div>
        </div>
    )
}

export default ProfilHeader;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar-filler {
    background-color: #000;
    height: 200px;
}

.about-us-zone {
    width: 100%;
    max-width: 1700px;
    padding: 0 130px;
    margin-left: auto;
    margin-right: auto;
}

.description-text {
    max-width: 650px;
    padding-right: 80px;
    text-align: justify;
    align-self: center;
}

.description-img {
    margin-top: auto;
    margin-bottom: auto;
}

.description-img img {
    width: 100%;
    min-width: 400px;
}

.team-img {
    width: 100%;
    max-width: 1200px;
}

@media screen and (max-width: 1000px) {
    .description-img {
        display: none;
    }

    .description-text {
        padding-right: 0px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/style/aboutUs.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".navbar-filler {\n    background-color: #000;\n    height: 200px;\n}\n\n.about-us-zone {\n    width: 100%;\n    max-width: 1700px;\n    padding: 0 130px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.description-text {\n    max-width: 650px;\n    padding-right: 80px;\n    text-align: justify;\n    align-self: center;\n}\n\n.description-img {\n    margin-top: auto;\n    margin-bottom: auto;\n}\n\n.description-img img {\n    width: 100%;\n    min-width: 400px;\n}\n\n.team-img {\n    width: 100%;\n    max-width: 1200px;\n}\n\n@media screen and (max-width: 1000px) {\n    .description-img {\n        display: none;\n    }\n\n    .description-text {\n        padding-right: 0px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
    font-family: 'Poppins', sans-serif;
}

body {
    background: #EFEEEE;
}

.title {
    font-size: 60px;
    font-weight: 700;
}

.txt-15 {
    font-size: 15px;
}

.txt-20 {
    font-size: 20px;
}

.txt-30 {
    font-size: 30px;
}

.txt-40 {
    font-size: 40px;
}

.center {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.inline-flex {
    display: inline-flex;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.btn {
    padding: 24px 32px;
    border-radius: 20px;
}

.btn:hover {
    transform: scale(1.1,1.1);
}

.col a {
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/style/main.css"],"names":[],"mappings":"AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');\n\n* {\n    font-family: 'Poppins', sans-serif;\n}\n\nbody {\n    background: #EFEEEE;\n}\n\n.title {\n    font-size: 60px;\n    font-weight: 700;\n}\n\n.txt-15 {\n    font-size: 15px;\n}\n\n.txt-20 {\n    font-size: 20px;\n}\n\n.txt-30 {\n    font-size: 30px;\n}\n\n.txt-40 {\n    font-size: 40px;\n}\n\n.center {\n    text-align: center;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.inline-flex {\n    display: inline-flex;\n}\n\n.space-between {\n    display: flex;\n    justify-content: space-between;\n}\n\n.btn {\n    padding: 24px 32px;\n    border-radius: 20px;\n}\n\n.btn:hover {\n    transform: scale(1.1,1.1);\n}\n\n.col a {\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
